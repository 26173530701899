import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie'
import { Button, Icon, StructuredModal, Typography } from 'tn-components-library';
import Config from "./config.json";
import './styles.css';
import gtm from '../../usagetracker/GTM';
import Announcements from '../../http/Announcements';
import { getCookieDomainInfo, getCookieValue } from '../../utils';

const ModuleCard = (props) => {
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false)
  const [accessModules, setAccessModules] = useState([])
  const [noAccessModules, setNoAccessModules] = useState([])
  const [iconName, setIconName] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [trackingLabel, setTrackingLabel] = useState('')
  const messages = props.messages

  const openURL = (event, item, urlKey, path) => {
    event.stopPropagation();
    let url = ''
    if (urlKey === 'recruit') {
      url = window.Gemini.Config.recuritURL
    } else {
      url = `${window.Gemini.Config.plan2xURL}/${path}`
    }
    gtm.track({
      event: 'tncr.click',
      category: item.trackingtitle,
      action: 'Module opened',
      page: 'TN Homepage'
    })
    setTimeout(() => {
      window.location.href = url;
    }, 500);
    return
  }

  const modalRequest = async(data = {}) => {
    const envVariable = window.Gemini.UserData.profileName
    setIsRequestModalOpen(true)
    setModalTitle(data.title)
    setIconName(data.imagename)
    setTrackingLabel(data.trackingtitle)
    const isCookieValue = getCookieValue(data.cookiekey)
    if (isCookieValue && isCookieValue === 'false') {
      Cookies.set(data.cookiekey, true, {
        domain: getCookieDomainInfo(),
      })
      if (envVariable === 'prod') {
        await handleRequestAPIAccess(data.typeofrequest)
      }
    }
  }

  const actions = [
    {
      category: 'primary',
      onClick: () => {
        setIsRequestModalOpen(false)
        setModalTitle('')
        setIconName('')
        gtm.track({
          event: "tncr.click",
          category: trackingLabel,
          action: 'Access Requested',
          page: 'TN Homepage'
        })
        setTrackingLabel('')
      },
      label: 'Ok',
      size: 'medium',
    }
  ]

  useEffect(() => {
    const moduleConfig = Object.keys(Config).map(code => ({ ...Config[code], isEnabled: props.appList.includes(code) }));
    const accessModulesData = moduleConfig.filter(item => item.isEnabled)
    const noAccessModulesData = moduleConfig.filter(item => !item.isEnabled)
    setAccessModules(accessModulesData)
    if (noAccessModulesData.length) {
    setNoAccessModules(noAccessModulesData)
      noAccessModulesData.forEach(item => {
        const isCookieValue = getCookieValue(item.cookiekey)
        if (!isCookieValue) {
          Cookies.set(item.cookiekey, false, {
            domain: getCookieDomainInfo(),
          })
        }
      })
    }
  }, [])

  const handleRequestAPIAccess = async (typeofrequest) => {
    const userData = window.Gemini.UserData
    const params = {
      email: userData?.userEmail,
      first_name: userData?.firstName,
      last_name: userData?.lastName,
      client_name: userData?.clientName,
      type_of_request: typeofrequest
    }
    return new Promise((resolve, reject) => Announcements.registerApiAccess(params).then((response) => response))
  }

  return (
    <>
      <div className='module-card-container'>
        {accessModules.length > 0 && <div className='module-header-text'>Your modules</div>}
        <div className='module-container access-module'>
          {
            accessModules.map((item, index) => {
              return (
                <>
                  <div className='module-card' key={index} onClick={(e) => openURL(e, item, item.code, item.path)}>
                    <Icon name={item.imagename} />
                    <Typography category='heading3' value={item.title} />
                    <div className={item.code !== "employeeValueProposition" ? 'module-desc' : 'module-desc-evp'}>{item.desc}</div>
                    <div className='modulecard-footer'>
                      <div className='modulecard-footer-text'>
                        <div>{item.code !== "employeeValueProposition" ? 'Previously' : ''}
                          <div>{item.code !== "employeeValueProposition" ? (item.code !== 'recruit' ? `Plan - ${item.prevtitle}` : item.prevtitle) : ''}</div>
                        </div>
                      </div>
                      <Button
                        category="ghost"
                        className='open-btn'
                        outline="true"
                        label={'Open module'}
                        onClick={(e) => openURL(e, item, item.code, item.path)}
                      />
                    </div>
                  </div>
                </>
              )
            })
          }
        </div>
      </div>
      {
        noAccessModules.length > 0 &&
        <div className='module-division'></div>
      }
      <div className='no-access module-card-container'>
        {noAccessModules.length > 0 && <div className='module-header-text'>Other modules</div>}
        <div className='module-container'>
          {
            noAccessModules.map((item, index) => {
              return (
                <div className='module-card' key={index} onClick={() => modalRequest(item)}>
                  <Icon name={item.imagename} />
                  <Typography category='heading3' value={item.title} />
                  <div className={item.code !== "employeeValueProposition" ? 'module-desc' : 'module-desc-evp'}>{item.desc}</div>
                  <div className='modulecard-footer'>
                    <div className='no-access-modulecard-footer-text'>
                      <div>{item.code !== "employeeValueProposition" ? 'Previously' : ''}
                        <div>{item.code !== "employeeValueProposition" ? (item.code !== 'recruit' ? `Plan - ${item.prevtitle}` : item.prevtitle) : ''}</div>
                      </div>
                    </div>
                    <Button
                      className='req-btn'
                      category="ghost"
                      outline="true"
                      label={'Request access'}
                      onClick={() => modalRequest(item)}
                    />
                  </div>
                </div>)
            })
          }
        </div>
      </div>
      {isRequestModalOpen && <StructuredModal actions={actions} className='request-modal' open={isRequestModalOpen} >
        <div className='modal-title'>
          <Icon name={iconName} />
          <Typography category='heading3' value={modalTitle} />
        </div>
        <p className='modal-desc'>{messages.formatMessage({ id: 'no_module_access_desc' })}</p>
      </StructuredModal>}
    </>
  )


}

export default ModuleCard
