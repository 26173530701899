import React from 'react';
import './styles.css';
import Tile from './Tile';


export default function ProductCard(props){
 const {productdetail = {} }  = props
 const URL = props.openURL
 const productList = props.productList || []
  if (productList.length === 1) {
    return (
      <div className="custom-role-homepage-container">
        <Tile product={productdetail[productList[0]]} className={"single-product-access"} block={"first"} openURL={URL} />
      </div>
    )
  }
  return (
    <>
      <div className="custom-role-homepage-container">
        <div className="custom-role-block-wrapper">
          {productList.length && productList.map((element, index) => {
            if (element && productdetail[element]) {
              return (
                <Tile product={productdetail[element]} className={"tile-block"} openURL={URL} key={index} />
              )
            }
            return null
          })}
        </div>
      </div>
    </>
  );
}