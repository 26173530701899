import React from 'react';
import PropTypes from 'prop-types';
import { MyAccount} from "talentneuron---ui-components-library";
import { TalentNeuronLogo,Accordion,Loader,Typography,Modal,Button } from 'tn-components-library'
import myAccountAPI from '../../http/getUserProfile'
import subscriptionsAPI from '../../http/apisubscriptions'
import SubscriptionDetails from './subscriptionDetails'
import http from '../../http'

import { hasProductAccess,getFormattedDate} from './../../utils';
import '../../styles.css';
import KeyCloakAuthInstance from '../../auth/keyAuth';

const Brand = () => (
  <span className="navbar-brand">
    <TalentNeuronLogo width='150' height='30' />
  </span>
)

class Account extends React.Component {

	
  constructor(props, context) {
		super(props, context);
    this.state = {
      userDetails : null,
      subscriptions : [],
      expandedId : null,
      subscriptionDetails : null,
      isSubscriptionDetailLoading : false,
      platformCountries : [],
      showEmailModal : false
    }
    this.handleChange = this.handleChange.bind(this);
    this.getSubscriptionDetails = this.getSubscriptionDetails.bind(this);
    this.getSubscriptionsTableHeader = this.getSubscriptionsTableHeader.bind(this);
    this.getApiSuiteUsecases = this.getApiSuiteUsecases.bind(this);
    this.sendEmailForSubscriberCredentials = this.sendEmailForSubscriberCredentials.bind(this);
    this.onPasswordChangeHandler = this.onPasswordChangeHandler.bind(this);
    this.getPersonaResponsibilities = this.getPersonaResponsibilities.bind(this);
    this.updateResponsibilities = this.updateResponsibilities.bind(this);
    this.updatePersona = this.updatePersona.bind(this);
    this.updateUserAccountApi = this.updateUserAccountApi.bind(this);
  }

  static contextTypes = {
    store: PropTypes.object,
    intl: PropTypes.object,
  };

  content = {
    "email_notifications" : this.context.intl.formatMessage({id: 'email_notifications'}),
    "marketing_emails" : this.context.intl.formatMessage({id: 'marketing_emails'}),
    "marketing_emails_desc" : this.context.intl.formatMessage({id: 'marketing_emails_desc'}),
    "shared_search_notifications" : this.context.intl.formatMessage({id: 'shared_search_notifications'}),
    "shared_search_notifications_desc" : this.context.intl.formatMessage({id: 'shared_search_notifications_desc'}),
    "data_settings" : this.context.intl.formatMessage({id: 'data_settings'}),
    "include_staffing_firms" : this.context.intl.formatMessage({id: 'include_staffing_firms'}),
    "include_staffing_firms_desc" : this.context.intl.formatMessage({id: 'include_staffing_firms_desc'}),
    "products_update_notifications" : this.context.intl.formatMessage({id: 'products_update_notifications'}),
    "products_update_notifications_desc" : this.context.intl.formatMessage({id: 'products_update_notifications_desc'}),
    "include_staffing_firms_info" : this.context.intl.formatMessage({id: 'include_staffing_firms_info'}),
    "include_anonymous_employer" : this.context.intl.formatMessage({id: 'include_anonymous_employer'}),
    "plan_new_job_postings" : this.context.intl.formatMessage({id: 'plan_new_job_postings'}),
    "plan_open_job_posting" : this.context.intl.formatMessage({id: 'plan_open_job_posting'}),
    "plan_job_postings_desc" : this.context.intl.formatMessage({id: 'plan_job_postings_desc'}),
    "plan_new_job_postings_info" : this.context.intl.formatMessage({id: 'plan_new_job_postings_info'}),
    "plan_open_job_postings_info" : this.context.intl.formatMessage({id: 'plan_open_job_postings_info'}),
    "job_postings" : this.context.intl.formatMessage({id: 'job_postings'}),
    "count_demand" : this.context.intl.formatMessage({id: 'count_demand'}),
    "new_job" : this.context.intl.formatMessage({id: 'new_job'}),
    "open_job" : this.context.intl.formatMessage({id: 'open_job'}),
  };

  componentDidMount() {
    let hasTNAPIaccess = hasProductAccess(window.Gemini.UserData.productDetails,"TalentNeuron API");
    http.all([
      myAccountAPI.getUserProfile(), 
      hasTNAPIaccess && subscriptionsAPI.getAPISubscriptions(),
      hasTNAPIaccess && subscriptionsAPI.getApiStatusLookup(),
      hasTNAPIaccess && subscriptionsAPI.getApiSuiteLookup(),
      hasTNAPIaccess && subscriptionsAPI.getApiTypeLookup(),
      hasTNAPIaccess && subscriptionsAPI.getApiQueryRange(),
      hasTNAPIaccess && subscriptionsAPI.getTNPlatformCountries()
    ])
    .then(http.spread((userData, subscriptions,apiStatusLookupData,apiSuiteLookupData,apiTypeLookupData,apiQueryRange,platformCountries) => {
      this.setState(prevState =>{
        return{
             ...prevState,
             userDetails : {
              "FirstName": userData.data.firstName,
              "LastName": userData.data.lastName,
              "Title": userData.data.title,
              "City": userData.data.city,
              "Country": userData.data.country,
              "Email": userData.data.email,
              "RoleName": userData.data.clientName,
              "UserID": userData.data.userID,
              "IsMarketingEmailActive": userData.data.isMarketingEmailActive,
              "IsSharedSearchOptedIn": userData.data.isSharedSearchOptedIn,
              "IsProductsUpdateOptedIn":userData.data.isProductsUpdateOptedIn,
              "IsStaffingOptedIn": userData.data.isStaffingOptedIn,
              "IsAnonymousOptedIn": userData.data.isAnonymousOptedIn,
              "IsNewJobOptedIn": userData.data.isNewJobOptedIn,
              "IsOpenJobOptedIn": userData.data.isOpenJobOptedIn,
              "personaID": userData.data.personaID || 0,
              "responsibilityIdsCSV": userData.data.responsibilityIdsCSV
            },
            subscriptions : subscriptions && subscriptions.length && this.sortSubscriptions(subscriptions) ,
            apiStatusLookupData ,
            apiSuiteLookupData,
            apiTypeLookupData,
            apiQueryRange,
            platformCountries
        }
     },() => (this.state.apiTypeLookupData && this.state.apiTypeLookupData.length) && this.getApiSuiteUsecases())
    }));
  }

  sortSubscriptions(subscriptionsList){
    let active = [],idle=[],deactivated=[],expired=[];
    subscriptionsList.forEach(sub => {
      (sub.status == 2) ? active.push(sub) : (sub.status == 1) ? idle.push(sub) : 
      (sub.status == 3) ? deactivated.push(sub) : expired.push(sub);
    });
    return [...active,...idle,...deactivated,...expired];
  }

  getApiSuiteUsecases() {
    let requests = [];
    let requestIdOrder = [];
    this.state.apiTypeLookupData.forEach(i => {
      requests.push(subscriptionsAPI.getApiSuiteUsecases(i.id));
      requestIdOrder.push(i.id);
    });
    http.all(requests).then(responses => {
      let apiUseCasesLookupData = {};
      responses.forEach((response, index) => {
        let useCases = [];
        response.forEach(useCase => {
          useCases.push({
            id: useCase.id,
            name: useCase.name,
            apiList: useCase.apiList
          });
        });
        apiUseCasesLookupData[requestIdOrder[index]] = useCases;
      });
      this.setState({
        apiUseCasesLookupData
      });
    });
  }

  getSubscriptionTypeStatusLookup(id,t){
    if(t == "status"){
      return this.state.apiStatusLookupData.filter(i => i.id === id && i.name)[0].name;
    }else if(t == "apiSuites"){
      return this.state.apiSuiteLookupData.filter(i => i.id === id && i.name)[0].name;
    }else{
      return this.state.apiTypeLookupData.filter(i => i.id === id && i.name)[0].name;
    }
  }

  componentWillUnmount(){
    this.setState({
      userDetails : null,
      subscriptions : [],
      expandedId : null,
      subscriptionDetails : null
    })
  }
	
  accordionSummary(title1,title2,title3,title4,startDate,endDate,isClosed){
    return (<div className="subscriptionSummary">
              <div className="name">{title1}</div>
              {isClosed && <div className="typestatus"><span>{title2}/{title3}</span><span>{`${getFormattedDate(startDate)} - ${getFormattedDate(endDate)}`}</span></div>}
              {isClosed && <div className="apisuite">{title4}</div>}
            </div>)
  }

  getPersonaResponsibilities(){
    return http.get(`${window.Gemini.Config.adminpublicserviceURL}/v1/userprofiles/allcategory`)
  }

  updateUserAccountApi(userDetails){
    return http
      .post(
        `${window.Gemini.Config.adminpublicserviceURL}/v1/user/updateusermyaccount`,
        userDetails
      )
      .then(
        http.post(`${window.Gemini.Config.homeURL}/cache/clear/userdetails`)
      );
  }

  updatePersona(persona){
      return http.patch(
        `${window.Gemini.Config.adminpublicserviceURL}/v1/userprofiles/update/persona`,
        persona,
      );
  }

  updateResponsibilities(responsibilities){
    return http.patch(
      `${window.Gemini.Config.adminpublicserviceURL}/v1/userprofiles/update/responsibilities`,
      responsibilities
    );
  }

  onPasswordChangeHandler(){
    let url = `${window.location.origin}/changePassword?token=${KeyCloakAuthInstance.getAccessToken()}&redirecturl=${KeyCloakAuthInstance.getLogoutUrl()}`;
    window.open(url, '_self');
  }

  getSubscriptionsTableHeader(isAccordionClosed){
    return(
      isAccordionClosed && (<div className="sub-header">
        <div className="sub-header-name">{this.context.intl.formatMessage({id: 'subscription_name'})}</div>
        <div className="sub-header-typestatus">{this.context.intl.formatMessage({id: 'type_status'})}</div>
        <div className="sub-header-apisuite">{this.context.intl.formatMessage({id: 'api_suites'})}</div>
      </div>)
    )
  }

  getSubscriptionDetails(key){
    http.all([
      subscriptionsAPI.getSubscriptionDetail(key),
      subscriptionsAPI.getConsumedCreditsOfUser(key),
      subscriptionsAPI.getConsumedCreditsOfClient(key)
    ])
    .then(http.spread((subscriptionData, consumedCredits, consumedCreditsByClient) => {
      this.setState({
        subscriptionDetails : subscriptionData != null && this.cleanUpSubscriptionDetail(subscriptionData,consumedCredits,consumedCreditsByClient),
        isSubscriptionDetailLoading : false
      })
    }));
  }

  cleanUpSubscriptionDetail(subscriptionData, consumedCredits, consumedCreditsByClient) {
    let newSubscriptionData = JSON.parse(JSON.stringify(subscriptionData));
    let countries = [], useCases = [];
    this.state.platformCountries && this.state.platformCountries.forEach(country => {
      if (subscriptionData.locations.indexOf(country.code) > -1) {
        countries.push(country.countryName);
      }
    });
    subscriptionData.useCaseApiMap && Object.entries(subscriptionData.useCaseApiMap).forEach(([usecaseId, apiIds]) => {
      let useCaseData = this.state.apiUseCasesLookupData[subscriptionData.apiSuiteId].find(useCase => useCase.id === parseInt(usecaseId));
      if (useCaseData && Array.isArray(useCaseData.apiList)) {
        let apiList = useCaseData.apiList;
        let useCaseName = useCaseData.name;
        if (apiIds.length === apiList.length) {
          useCases.push(`${useCaseName} (all)`);
        } else {
          let apiNames = apiIds.map(apiId => {
            return apiList.find(api => api.id === apiId)?.name;
          }).filter(name => name).join(", ");
          useCases.push(`${useCaseName} (${apiNames})`);
        }
      }
    });
    newSubscriptionData.countries = countries;
    newSubscriptionData.useCases = useCases;
    newSubscriptionData.consumedCreditsOfUser = consumedCredits.creditsOfUser;
    newSubscriptionData.consumedCreditsOfClient = consumedCreditsByClient.count
    newSubscriptionData.apiQueryRangeValue = this.state.apiQueryRange && this.state.apiQueryRange.length && this.state.apiQueryRange.filter(i => i.id == subscriptionData.queryRange)[0].name;
    return newSubscriptionData;
  }

  handleChange(id){
    this.setState(prevState =>{
      return{
           ...prevState,
           expandedId : prevState.expandedId === id ? null : id,
           isSubscriptionDetailLoading : prevState.expandedId === id  ? false : true
      }
   },() => this.state.expandedId != null && this.getSubscriptionDetails(this.state.expandedId))
  }

  sendEmailForSubscriberCredentials(key,name, targetElement){
    targetElement?.classList?.add('processing-req')
    subscriptionsAPI.sendEmailForSubscriberCredentials(key,name).then((isSuccess)=>{
      if(isSuccess){
        this.setState({
          showEmailModal : true
        });
      }
      targetElement?.classList?.remove('processing-req')
    });
  }

  closeModalHandler(){
    this.setState({
      showEmailModal : false
    });
  }

  calculateIsActive(status) {
    return status === 1 || status === 2;
  }
  
  render() {
    let hasTNAPIaccess = hasProductAccess(window.Gemini.UserData.productDetails,"TalentNeuron API");
    return (
        <div id={"userSettings"}>
            <div className={"navbar-header"}>
              <div className={"main-container"}>
                <Brand/>
              </div>
            </div>
            <div className={`main-container ${window.Gemini.UserData.PLAN2x ? "display-tnp-section" : "hide-tnp-section"} ${hasTNAPIaccess && this.state.subscriptions && this.state.subscriptions.length ? "hasSubscriptions":""}`}>
              {this.state.userDetails ? <MyAccount
                  userDetails={this.state.userDetails}
									language={this.content}
									onPasswordChangeHandler = {this.onPasswordChangeHandler}
                  getPersonaResponsibilities = {this.getPersonaResponsibilities}
                  updateResponsibilities = {this.updateResponsibilities}
                  updatePersona = {this.updatePersona}
                  updateUserAccountApi = {this.updateUserAccountApi}
					  /> : <Loader/>}
              {(hasTNAPIaccess && this.state.subscriptions && this.state.subscriptions.length) ? <div className='subscriptionContainer'>
                <Typography category={'heading3'} className={'H3'} value={"TalentNeuron APIs Subscriptions"}/>
                  {this.getSubscriptionsTableHeader(this.state.expandedId === null)}
                  {
                    this.state.subscriptions.map((i,d) => {
                      const isActive = this.calculateIsActive(i.status);
                      return (
                        <Accordion key={d} toggleExpanded={() =>this.handleChange(i.subscriptionKey)} isOpen={this.state.expandedId === i.subscriptionKey} accordionSummary={this.accordionSummary(i.subscriptionName,this.getSubscriptionTypeStatusLookup(i.type,"type"),this.getSubscriptionTypeStatusLookup(i.status,"status"),this.getSubscriptionTypeStatusLookup(i.apiSuiteId,"apiSuites"),i.startDate,i.endDate,!(this.state.expandedId === i.subscriptionKey))}>
                          <> 
                          {this.state.subscriptionDetails != null && !this.state.isSubscriptionDetailLoading ? <SubscriptionDetails emailCallback = {this.sendEmailForSubscriberCredentials} data={this.state.subscriptionDetails} status={this.getSubscriptionTypeStatusLookup(i.status,"status")} type={this.getSubscriptionTypeStatusLookup(i.type,"type")} apiSuite ={this.getSubscriptionTypeStatusLookup(i.apiSuiteId,"apiSuites")} isActive={isActive} intl={this.context.intl} /> : <Loader/>}
                          </>
                        </Accordion>
                      )
                    })
                  }
              </div> : null}
              {
                this.state.showEmailModal ? <EmailModalPrompt closeModalHandler = {this.closeModalHandler.bind(this)} intl={this.context.intl} /> : null
              }
            </div>
        </div>);
  }
}

const EmailModalPrompt = (props) => {
  const intl = props.intl
    return (
      <div className={"subscriptionEmailModal"}>
        <Modal open={true}>
            <div className={"modal-header"}><Typography value={intl.formatMessage({ id: 'view_subscription_secret' })} category={'heading3'}/></div>
            <div className={"modal-text"}>
            {intl.formatMessage({ id: 'email_modal_text' })}
            </div>
            <div className={"button-row"}>
                <Button category='primary' onClick={() => {
                    props.closeModalHandler()
                }} label={intl.formatMessage({ id: 'ok' })}/>
            </div>
        </Modal>
      </div>
    );
}

Account.contextTypes = {
  intl: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
}


export default Account;
