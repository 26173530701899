import React from 'react';
import { Button, Typography, Icon } from 'tn-components-library'
import './styles.css';


export default function Tile(props) {
  const { product, openURL, block, className } = props
  return (
    <div className={className}>
      <div className="title-icon-block">
        <span className={`${product.imagename} icon`}></span>
        <div className="title-wrapper">
          <Typography category={'heading1'} value={product.title} />
          <Typography category={'heading3'} value={product.desc} />
        </div>
      </div>
      <Button
        category="primary-outline"
        onClick={() => openURL(product.urlkey)}
        outline="true"
        label={product.btntext}
      />
    </div>
  )
}