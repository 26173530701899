import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Button} from 'tn-components-library'
import './styles.css'

class BannerAccordion extends Component {
    render() {
        let style = {
            height: this.props.isOpen ? "340px" : 0,
            WebkitTransition: "height 0.2s linear 0s",
            msTransition: "height 0.2s linear 0s",
            transition: "height 0.2s linear 0s",
            overflow: "hidden"
        };
        let img = `data:image/${this.props.imgext};base64,${this.props.imgdata}`
        return (
            <div className="main-container">
                    <div style={style}>
                        <div className="accordion-container" ref="inner">
                            <a className="pull-right btn-link" type="link"
                                    onClick={this.props.showOrHideBanner}>Hide</a>
                            <div className="accordion">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h1 className="heading1"> {this.props.title} <sup>{this.props.titletag}</sup></h1>
                                        <div className="new-tool-desc margin-bottom-25">
                                            {this.props.subtitle}
                                        </div>
                                        <Button category='primary-outline' label={this.props.ctalabel}
                                                onClick={() => this.props.redirectTo(this.props.ctalink)}/>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={img} className="new-tool-img"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }
}

export default BannerAccordion;