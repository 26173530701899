import http from './'
import KeyCloakAuthInstance from '../auth/keyAuth'

const config = window.Gemini.Config

export const getMimicClientList = (term) =>
  http
    .get(config.adminpublicserviceURL + '/v1/mimicclient?term=' + term)
    .then((response) => response.data.data)

export const beginClientMimic = (clientId) =>
  http
    .post(config.adminpublicserviceURL + '/v1/mimicclient?clientId=' + clientId)
    .then((response) => response.data)
    .catch((e) => e)

export const handleEndSession = async () =>
  http
    .delete(config.adminpublicserviceURL + '/v1/mimicclient')
    .then((response) => {
      console.log(response)
      if (response.data.isError) {
        console.log('End mimic client session API Failed - IsError is true')
        return
      }
      KeyCloakAuthInstance.logout()
    })
    .catch((error) => {
      console.log('End mimic client session API Failed')
      console.log(error)
    })
