import React, { useEffect } from 'react'
import http from './'


export const GetInsights = () => {
  const [insights, setInsights] = React.useState([])
  useEffect(() => {
    http
      .post(`${window.Gemini.Config.adminpublicserviceURL}/v1/cms/gettninsights`, {
        categories: 'All',
        pageno: 1,
        totalrecords: 100,
        fromdate: '2020-01-01',
        todate: '2099-12-31',
        searchtext: '',
      })
      .then((response) => {
        const insightsReponse = response.data?.data?.insightslist.map((insight) => {
          return {
            id: insight.id,
            title: insight.title,
          }
        })
        setInsights(insightsReponse)
      })
      .catch((e) => {
        return e
      })
  }, [])

  return insights
}
