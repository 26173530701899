import React from 'react'
import SideNav from './SideNav'
import { UtilityBar } from 'tn-components-library'
import { handleEndSession } from '../../http/viewAs'

const Header = () => {
  const utilityBarDetails = {
    clientName: window.Gemini.UserData.clientName
  }
  return (
    <>
      <header className='navbar' id='navbar'>
        {window.Gemini.UserData.mimicClient && (
          <UtilityBar utilityBarDetails={utilityBarDetails} handleEndSession={handleEndSession} />
        )}
        <SideNav />
      </header>
    </>
  )
}

export default Header
