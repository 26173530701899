import http from "./index";

/**
 * store procedure to update the user preferences
 *
 * @author : pdiouf
 * @Date : 27/01/2020
 *
 */


const getAnnouncementByDate  = (currentDate) => {

    return new Promise ((resolve, reject) =>{
        http.get(`${window.Gemini.Config.adminpublicserviceURL}/v1/cms/getmajorannouncementbydate?date=${currentDate}`)
            .then(res => {
                if(res == null) return reject(null);

                if(res.data.isError){
                    console.log("Error =>" + res.data )
                    reject(null);
                }else{
                     resolve(res.data);
                }
            })
            .catch(err =>{
                 console.log(err);
                 reject(null);
                }
            )
    })
}

const getNotificationByDate  = (currentDate) => {

    return new Promise ((resolve, reject) =>{
        http.get(`${window.Gemini.Config.adminpublicserviceURL}/v1/cms/getnotificationbydate?date=${currentDate}`)
            .then(res => {
                if(res == null) return reject(null);

                if(res.data.isError){
                    console.log("Error =>" + res.data )
                    resolve([]);
                }else{
                     resolve(res.data);
                }
            })
            .catch(err =>{
                 console.log(err);
                 reject(null);
                }
            )
    })
}


const setMajorAnnouncementUserPreferences = (announcementId, collapsed) => {
    http.patch(
        `${window.Gemini.Config.adminpublicserviceURL}/v1/tnr/setmajorannouncementuserpreferences?announcementId=${announcementId}&collapsed=${collapsed}`)
        .then(() => {
            console.log("User announcements preferences are set correctly")
        })
        .catch(err =>{
                console.log(err);
            }
        )
}

const registerApiAccess = (params) => {
    let url = `${window.Gemini.Config.homeURL}/registerapiaccess?email=${params.email}&first_name=${params.first_name}&last_name=${params.last_name}`
    if (params.client_name) {
        url = url + `&client_name=${params.client_name}&type_of_request=${params.type_of_request}`
    }
    return new Promise((resolve, reject) => {
        http.get(url).then((response) => {
            resolve(response.data);
        })
            .catch((err) => {
                reject(null)
            })
    })
}

const recentSearchTracking = (widgetDetails) => {
    http.post(`${window.Gemini.Config.adminpublicserviceURL}/tnrecentsearch/`, widgetDetails)
        .then(() => {
            console.log("User recent search updated")
        })
        .catch(err =>{
                console.log(err);
            }
        )
}

const requestApiAccess = (params) => {
    return new Promise((resolve, reject) => {
    http.get(`${window.Gemini.Config.homeURL}/v1/send/beta/access/plan2x?moduleName=${params.moduleName}&token=${params.token}`).then((response) => {
        resolve(response.data)
    })
    }).catch((err) => {
        reject(null)
    })
}

export default {getAnnouncementByDate, getNotificationByDate, setMajorAnnouncementUserPreferences, registerApiAccess, recentSearchTracking, requestApiAccess}

