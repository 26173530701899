import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import "./profiles/index.js"
import React from 'react';
import { TNThemeProvider } from 'tn-components-library'
import ReactDOM from 'react-dom';
import "./profiles/index.js"
import './styles.css';
import Root from './container/layout/Root';
import ErrorBoundary from './component/ErrorBoundary';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { LoadingCharts } from 'tn-components-library'
import authReduce from './auth/reducer';
import authDetails from './auth/actionCreator';
import authAPI from './auth/auth.js';
import authObj from './auth';

// import fr from 'react-intl/locale-data/fr';
import messages_en from "./localization/en.json";
import KeyCloakAuthInstance from './auth/keyAuth.js';
// import messages_fr from "./localization/fr.json";
ReactDOM.render(<div className='root-loading'><LoadingCharts title='Loading' subTitle='TalentNeuron' /></div>, document.getElementById('root'));
function doAuthentication() {
  KeyCloakAuthInstance.tryAuthentication().then(isAuthSuccess => {
    if(isAuthSuccess){
      authAPI.getAuthData().then((response) => {
        authObj.parseAuthResponse(response);
        store.subscribe(renderRoot);
        store.dispatch(authDetails.setAccessRequest({ plan2x : false}));
      });
    }
  });
}
doAuthentication();

const messagesList = {
  'en': messages_en,
  // 'fr':messages_fr
};
addLocaleData([...en]);
//addLocaleData([...en, ...fr]);

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
const language = (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

// Split locales with a region code
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

// Try full locale, fallback to locale without region code, fallback to en
const messages = messagesList[language] || messagesList[languageWithoutRegionCode] || messagesList.en;

const store = createStore(authReduce);
const renderRoot = () => {

  ReactDOM.render(
    <IntlProvider locale={language} messages={messages}>
      <TNThemeProvider>
      <Provider store={store}> 
        <ErrorBoundary>
          <Root />
        </ErrorBoundary>
      </Provider>
      </TNThemeProvider>
    </IntlProvider>, document.getElementById('root'));
};



