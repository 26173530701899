export default (state, action) => {
  if (state === undefined) { state = {}; }
  switch (action.type) {
    case "SET_ACCESS_REQUEST":
      state["accessrequest"] = action.accessrequest;
      return state;
    case "SET_TOOLTIP_INFO":
      state["tooltipInfo"] = action.tooltipInfo;
      return state;
    default:
      return state
  }
}