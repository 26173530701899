
const setAccessRequest = (accessrequest) =>{
  return {
    type: 'SET_ACCESS_REQUEST',
    accessrequest
  }
}

const setInfoTooltipData = (tooltipInfo) => {
  return {
    type: 'SET_TOOLTIP_INFO',
    tooltipInfo
  }
}

export default { setAccessRequest, setInfoTooltipData};