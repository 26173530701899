import http from "./index";


const getInfoTooltip = () => {
    const infotooltip = { infotooltip: [], isError: true }
    return new Promise((resolve, reject) => {
        http.get(window.Gemini.Config.adminpublicserviceURL + '/v1/tnp/planfeature/').then(response => {
            if (!response.data || response.data.isError || !response.data.data || !response.data.data.planfeature) {
                resolve(infotooltip)
                return
            }
            infotooltip.infotooltip = response.data.data.planfeature
            infotooltip.isError = false
            resolve(infotooltip)
        }).catch((error) => {
            resolve(infotooltip)
        })
    })
}

const setInfoToolAsViewed = (request) => {
    return new Promise((resolve, reject) => {
        http.post(window.Gemini.Config.adminpublicserviceURL + '/v1/tnp/planfeature/', request).then(function (response) {
            resolve(response.data.data)
        })
    })
}

export default { setInfoToolAsViewed, getInfoTooltip }