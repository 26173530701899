import React, {Component} from 'react'
import {Modal, Button,Typography} from 'tn-components-library'
import './styles.css'
import PropTypes from "prop-types";

class AnnouncementPrompt extends Component {

    render() {
        let messages = this.context.intl;
        return (

            <div className={"majorAnnouncement prompt"}>
                <Modal open={true}>
                    <div className={"modal-header"}><Typography value={messages.formatMessage({id: "major_announcement_title"})} category={'heading3'}/></div>
                    <div className={"modal-text"}>
                        <p className="change-country-body-text">
                            {messages.formatMessage({id: "major_announcement_description_part1"})}
                            {" " + this.props.platformname+ ". " }
                            {messages.formatMessage({id: "major_announcement_description_part2"})}
                        </p>
                    </div>
                    <div className={"button-row"}>
                        <Button category='primary' onClick={() => {
                            this.props.closeHandler(false)
                        }} label={messages.formatMessage({id: "major_announcement_cta_label"})}/>
                    </div>
                </Modal>
            </div>
        );
    }
}
AnnouncementPrompt.contextTypes = {
    intl: PropTypes.object.isRequired
}
export default AnnouncementPrompt

