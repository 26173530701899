import React from "react";
import Cookies from 'js-cookie'
import PropTypes from 'prop-types';
import Announcements from '../../http/Announcements'
import "./styles.css";
import { Button, Modal, UniversalSearchV2, NewProductIds, FeatureAnnouncement } from "tn-components-library";
import gtm from "../../usagetracker/GTM";
import TalentNeuronLogo from '../../assets/images/TalentNeuron-logo.svg';
import TalentIntelligenceApiImage from '../../assets/images/talent-intelligence-api-image.png';
import DevelopFriendlyApiImage from '../../assets/images/talent-intelligence-api.png';
import { getCookieDomainInfo, getCookieValue, getRolesFromProductDetails } from "../../utils";
import http from "../../http";

const onWidgetClick = (WidgetDetails) => {
    Announcements.recentSearchTracking(WidgetDetails)
}

class TNApiIntermediatePage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isModalOpen: false,
            isUserAgreed: true,
            isRegisterSuccess: false,
            insights: [],
            recentSearches: [],
            featureAnnouncement: null,
            isFeatureEnabled: false,
        }
        gtm.init(window.Gemini.UserData)
        this.handleClickViewDoc = this.handleClickViewDoc.bind(this)
        this.handleClickContinue = this.handleClickContinue.bind(this)
        this.args = this.args.bind(this)
    }

    async componentDidMount() {
        const productPermission = {
            Recruit: window.Gemini.UserData.GEMINI,
            Plan: window.Gemini.UserData.PLAN2x,
            Custom_Roles: window.Gemini.UserData.customRoleEnabled,
            DataFeed: window.Gemini.UserData.dataFeedEnabled,
            Admin: window.Gemini.UserData.adminEnabled,
            Datafeeds: window.Gemini.UserData.dataFeedEnabled
        };
        document.body.classList?.add('talent-intelligence-api')
        const cookieValue = getCookieValue('isAgreedForTalentIntelligenceAPI')
        if (cookieValue === 'true') {
            this.setState({
                isUserAgreed: false
            })
        }
        if (!cookieValue) {
            Cookies.set('isAgreedForTalentIntelligenceAPI', false, {
                domain: getCookieDomainInfo(),
            })
        }
        let that = this;
        const date = new Date();
        let currentDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        let insightsOptions = {
            categories: 'All',
            pageno: 1,
            totalrecords: 100,
            fromdate: '2020-01-01',
            todate: '2099-12-31',
            searchtext: '',
        }
        const insightURL = window.Gemini.Config.adminpublicserviceURL + '/v1/cms/gettninsights'
        const recentSearchUrl = window.Gemini.Config.adminpublicserviceURL + '/tnrecentsearch/'
        await Promise.resolve(Announcements.getNotificationByDate(currentDate)).then((response) => {
            const notificationResponse = response.data
            const notificationsData = notificationResponse?.notifications || []
            if (notificationsData?.length) {
                const notifications = []
                const announcements = notificationsData.sort((prev, next) => (prev.id > next.id ? -1 : 1))
                if (announcements.length) {
                    let tag = announcements[0].platformtag
                    if (tag) {
                        tag = tag.replace(/\s+/g, ' ')?.trim()?.replace(' ', '_')
                    }
                    if (tag === null || productPermission[tag]) {
                        notifications.push(announcements[0]);
                    }
                    this.setState({
                        isFeatureEnabled: notifications.length > 0,
                        featureAnnouncement: notifications?.[0] || null
                    })
                }
            }
        })
        await Promise.resolve(http.post(insightURL, insightsOptions)).then((response) => {
            const insightResponse = response.data
            if (!insightResponse.isError && insightResponse.data && insightResponse.data.insightslist?.length) {
                const insightList = insightResponse.data.insightslist || [];
                const insights = insightList.slice(0, 3);
                that.setState({
                    insights: insightList,
                    topInsights: insights
                });
            }
        })
        await Promise.resolve(http.get(recentSearchUrl)).then((response) => {
            const recentSearchResponse = response.data
            if (!recentSearchResponse.isError && recentSearchResponse.data && recentSearchResponse.data.tnrecentsearch) {
                const recentSearches = recentSearchResponse.data.tnrecentsearch || [];
                that.setState({
                    recentSearches: recentSearches
                });
            }
        })
    }

    componentWillUnmount () {
        document.body.classList.remove('talent-intelligence-api')
    }

    handleClickViewDoc = (value) => {
        if (this.state.isUserAgreed) {
            this.setState({
                isModalOpen: value
            })
            return
        }
        window.location.href = window.Gemini.Config.axonDocURL;
    }

    handleClickContinue = () => {
        const envVariable = window.Gemini.UserData.profileName
        gtm.track(gtm.events.linkClick, {
            action: 'Consent for communication given',
            category: 'CTA clicked',
            label: window.Gemini.UserData.userEmail,
            page: 'API landing page'
        });
        Cookies.set('isAgreedForTalentIntelligenceAPI', true, {
            domain: getCookieDomainInfo(),
        })
        if (envVariable === 'prod') {
            return this.handleClickRegisterApiAccess()
        }
        window.location.href = window.Gemini.Config.axonDocURL;
    }

    handleClickRegisterApiAccess = async () => {
        const userData = window.Gemini.UserData
        const params = {
            email: userData?.userEmail,
            first_name: userData?.firstName,
            last_name: userData?.lastName
        }
        new Promise((resolve, reject) => {
            Announcements.registerApiAccess(params).then((response) => {
                this.setState({
                    isModalOpen: false
                });
                window.location.href = window.Gemini.Config.axonDocURL;
            })
        })
    }

    args() {
        if (window.Gemini.UserData) {
          return {
            roles: getRolesFromProductDetails(window.Gemini.UserData?.productDetails),
            planAccess: window.Gemini.UserData.PLAN2x,
            recruitAccess: window.Gemini.UserData.GEMINI,
            customRoleAccess: window.Gemini.UserData.customRoleEnabled,
            dataFeedAccess: window.Gemini.UserData.dataFeedEnabled,
            activeProduct: NewProductIds.HOME,
            urls: {
              plan: `${window.Gemini.Config.plan2xURL}#`,
              insight: `${window.Gemini.Config.homeURL}/learnmore/insights`,
              recruit: `${window.Gemini.Config.recuritURL}#`,
              home: window.Gemini.Config.homeURL,
              customrole: window.Gemini.Config.customRoleURL,
              datafeeds: window.Gemini.Config.dataFeedURL,
              customSolution: `${window.Gemini.Config.homeURL}/customsolutions`,
            },
            onClickCallback: onWidgetClick
          }
        }
      }

    render() {
        const messages = this.context.intl;
        const { isFeatureEnabled, featureAnnouncement } = this.state;
        let finalPropsforSearch = { ...this.args(), insights: this.state.insights, recentSearches: this.state.recentSearches }
        return (
            <>
                <div className="universal-search-header-container">
                    <div className="logo-header">
                        <a href={window.Gemini.Config.homeURL}>
                            <img src={TalentNeuronLogo} alt="logo" width={"150px"} height={'22px'} />
                        </a>
                    </div>
                    <UniversalSearchV2 {...finalPropsforSearch} />
                    {
                        isFeatureEnabled && featureAnnouncement &&
                        <FeatureAnnouncement
                            id={featureAnnouncement.id}
                            title={featureAnnouncement.title}
                            message={featureAnnouncement.message}
                            ctaUrl={featureAnnouncement.ctaurl}
                            endDate={featureAnnouncement.enddate}
                            collapseOnScroll={false}
                        />
                    }
                </div>
                <div className="talent-intelligence main-container">
                    <div className="talent-intelligence-section">
                        <div className="section-left padding-right-10">
                            <div className="tn-header">{messages.formatMessage({ id: "talent_intelligence_api" })}</div>
                            <div className="tn-paragraph">
                                <p dangerouslySetInnerHTML={{ __html: messages.formatMessage({ id: "talent_intelligence_api_desc" }) }} />
                            </div>
                        </div>
                        <div className="section-right padding-10">
                            <img
                                src={TalentIntelligenceApiImage}
                                alt="talent-intelligence-api-image"
                                className="img"
                                width={"100%"}
                                height={"100%"}
                            />
                        </div>
                    </div>
                    <div className="talent-intelligence-section padding-bottom-0">
                        <div className="section-left padding-right-10">
                            <img
                                src={DevelopFriendlyApiImage}
                                alt="talent-intelligence-api"
                                className="img"
                                width={"100%"}
                                height={"100%"}
                            />
                        </div>
                        <div className="section-left padding-left-20">
                            <div className="tn-header">{messages.formatMessage({ id: "developer_friendly_api" })}</div>
                            <div className="tn-paragraph">
                                <p>
                                    {messages.formatMessage({ id: "developer_friendly_api_desc" })}
                                </p>
                            </div>
                            <div>
                                <Button
                                    label="View API Documentation"
                                    color="primary"
                                    className="api-button"
                                    onClick={() => this.handleClickViewDoc(true)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="intelligence-api-usage padding-top-70 margin-bottom-30">
                        <div className="tn-header margin-bottom-20">
                            {messages.formatMessage({ id: "why_use_talent_intelligence_api" })}
                        </div>
                        <div>
                            <div className="tn-sub-header">
                                <b>{messages.formatMessage({ id: "align_your_internal_taxonomy_with_market_data" })}</b>{" "}
                            </div>
                            <p className="tn-sub-header" dangerouslySetInnerHTML={{ __html: messages.formatMessage({ id: "why_use_talent_intelligence_api_desc" }) }} />
                        </div>
                        <div>
                            <div className="tn-sub-header">
                                <b>{messages.formatMessage({ id: "connect_internal_and_external_data_into_a_single_user_view" })}</b>{" "}
                            </div>
                            <p className="tn-sub-header">
                                {messages.formatMessage({ id: "as_talent_analytics_evolves" })}
                            </p>
                        </div>
                        <div>
                            <div className="tn-sub-header">
                                <b>{messages.formatMessage({ id: "standardize_your_analytics_process" })}</b>{" "}
                            </div>
                            <p className="tn-sub-header">
                                {messages.formatMessage({ id: "as_hr_Data_becomes_more_ubiquitous" })}
                            </p>
                        </div>
                    </div>
                    {/* <div className="talent-intelligence-section padding-bottom-0">
                    <div className="section-left padding-right-10">
                        <div className="tn-header">
                            {messages.formatMessage({ id: "webinar_introducing_the_talent_intelligence_api" })}
                        </div>
                        <p className="tn-paragraph" dangerouslySetInnerHTML={{ __html: messages.formatMessage({ id: "webinar_introducing_the_talent_intelligence_api_desc" }) }} />
                        <div>
                            <Button
                                label="Register for Webinar"
                                color="primary"
                                className="api-button"
                                onClick={() => this.handleClickRegisterWebinar()}
                            />
                        </div>
                    </div>
                    <div className="section-left">
                        <img
                            src={assets/images/webinar-image.png}
                            alt="webinar-image"
                            width={"100%"}
                            height={"100%"}
                        />
                    </div>
                </div>
                <div className="talent-intelligence-footer">
                    <div className="footer">
                        <p>©2023 TalentNeuron</p>
                        <p className="phone"><a href="tel:+1-888-598-9905">1-888-598-9905</a></p>
                        <p><a href="https://www.talentneuron.com/terms-and-conditions/" target="_blank">Terms and Conditions</a></p>
                    </div>
                    <span className="linked-in">
                        <a href="https://www.linkedin.com/company/talentneuron/" target="_blank">in</a>
                    </span>
                </div> */}
                    {this.state.isModalOpen && (
                        <Modal className='modal-container' size='medium' open={true} removeBackDrop={false}>
                            <div className='content'>
                                <p className="tn-paragraph margin-bottom-0">
                                    {messages.formatMessage({ id: "view_api_doc_modal_content" })}
                                </p>
                            </div>
                            <div className='modal-footer'>
                                <Button category={'ghost'} label='Cancel' onClick={() => this.setState({ isModalOpen: false })} />
                                <Button category={'primary'} label='Continue' onClick={() => this.handleClickContinue(false)} />
                            </div>
                        </Modal>
                    )}
                    {/* {this.state.isRegisterSuccess && (
                    <Modal className='modal-container' size='medium' open={true} removeBackDrop={false}>
                        <div className='content'>
                            <h2 className={'header2'}>Thanks for registering!</h2>
                            <p className={'paragraph'}>Keep an eye on your email for details on how to join the webinar!</p>
                        </div>
                        <div className='modal-footer'>
                            <Button className='btn-confirm' category={'primary'} label='OK' onClick={() => this.setState({ isRegisterSuccess: false })} />
                        </div>
                    </Modal>
                )} */}
                </div>
            </>
        );
    }
};

TNApiIntermediatePage.contextTypes = {
    intl: PropTypes.object.isRequired,
    store: PropTypes.object
}

export default TNApiIntermediatePage;
