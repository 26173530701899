import http from '../http';
import KeyCloakAuthInstance from './keyAuth';

const getAuthData = () => {
  return new Promise((resolve, reject) => {
    http.get(window.Gemini.Config.homeURL + '/userdetails').then((response) => {
      if (response.data.IsError) {
        console.log('Auth API Failed - IsError is true');
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(null);
      }
      if (!response.data.isactive) {
        return KeyCloakAuthInstance.logout();
      }
      resolve(response.data);
    }).catch((error) => {
      console.log('Auth API Failed');
      console.log(error);
      // eslint-disable-next-line prefer-promise-reject-errors
      reject(null);
    })
  })
};

export default { getAuthData }