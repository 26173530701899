import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.css'

class Accordion extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <div>
                <div className={`new-tool ${this.props.isOpen ? 'hide' : ''} `}></div>
                <div className="main-container">
                    <div className={"new-tool-accordion"}>
                        <div className="new-tool-name">{this.props.title}</div>
                        <i className="ga ga-add02" onClick={this.props.toggleAccordion} />
                    </div>
                </div>
            </div>
        );
    }
}

Accordion.contextTypes = {
    intl: PropTypes.object.isRequired
}

export default Accordion;