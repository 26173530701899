if (!window.Gemini) {
    console.log("Window lop is empty")
    window.Gemini = {};
    window.Gemini.Config = {};
    window.Gemini.UserData = {};
    window.Gemini.Config = {
        "recuritURL": "http://recruit.tndev.aws.talentneuron.com",
        "plan2xURL": "http://plan.tndev.aws.talentneuron.com",
        "legacyPlanURL": "https://tnplan-qa.gartner.com/Overview.aspx",
        "legacyRecuritUrl": "https://www.wantedanalytics.com/wa/sessions/s3/login",
        "loginURL": "http://home.tndev.aws.talentneuron.com",
        "s3LogoutUrl": "resources/ceb_security_logout?redirecturl=",
        "s3Url": "http://login.tndev.aws.talentneuron.com/sso",
        "homeURL": "http://home.tndev.aws.talentneuron.com",
        "adminURL": "http://admin.tndev.aws.talentneuron.com/",
        "adminpublicserviceURL": "http://admin-publicservice.tndev.aws.talentneuron.com",
        "helpCenterURL": "https://talentneuron.my.site.com/Support/s",
        "customRoleURL": "http://customroles.tndev.aws.talentneuron.com",
        "dataFeedURL": "http://datafeeds.tndev.aws.talentneuron.com",
        "customRoleServiceURL": "http://customroles-service.tndev.aws.talentneuron.com",
        "axonDocURL": "http://home.tndev.aws.talentneuron.com/api",
        "staffingURL": "http://staffing.tndev.aws.talentneuron.com/",
        "kcAuthUrl": "http://axon-keykloak-service.tndev.aws.gartner.com",
        "kcAuthRealm" : "tn-ui",
        "kcAuthClient" : "tnui-web-app",
        "profilename": "sandbox"
    }
    window.Gemini.UserData = {
        "userID": 28447,
        "userAppCode": "TN",
        "appCodes": [
            "TN",
            "GEMINI"
        ],
        "clientCode": "C6D3CFD4-E90E-449F-A493-922214532644",
        "userEmail": "mohammed.hasan@gartner.com",
        "s3Token": "LnVfQciK-LrMo-YYQu-eRpV-D1c41k3mOh6O",
        "GEMINI": true,
        "PLAN2x": true,
        "TN": false,
        "WA": false,
        "competitiveIntel": false,
        "firstName": "hasan",
        "lastName": "mohammed",
        "customRoleEnabled": true,
        "dataFeedEnabled": true,
        "recuritNeuronEnabled": false,
        "adminEnabled": true,
        "sessionDate": 1666166755000,
        "sessionRenewed": false,
        "olds3Token": null,
        "clientName": "TN_Sales Account",
        "mimicClient": false,
        "subscribedCountries": [
            {
                "countryId": 1,
                "countryName": "Argentina",
                "categoryId": 2,
                "code": "AR"
            },
            {
                "countryId": 2,
                "countryName": "Australia",
                "categoryId": 1,
                "code": "AU"
            },
            {
                "countryId": 3,
                "countryName": "Belgium",
                "categoryId": 2,
                "code": "BE"
            },
            {
                "countryId": 4,
                "countryName": "Brazil",
                "categoryId": 1,
                "code": "BR"
            },
            {
                "countryId": 5,
                "countryName": "Canada",
                "categoryId": 1,
                "code": "CA"
            },
            {
                "countryId": 6,
                "countryName": "China",
                "categoryId": 2,
                "code": "CN"
            },
            {
                "countryId": 32,
                "countryName": "Czech Republic",
                "categoryId": 2,
                "code": "CZ"
            },
            {
                "countryId": 3024,
                "countryName": "Denmark",
                "categoryId": 3,
                "code": "DK"
            },
            {
                "countryId": 3025,
                "countryName": "Finland",
                "categoryId": 3,
                "code": "FI"
            },
            {
                "countryId": 7,
                "countryName": "France",
                "categoryId": 1,
                "code": "FR"
            },
            {
                "countryId": 8,
                "countryName": "Germany",
                "categoryId": 1,
                "code": "DE"
            },
            {
                "countryId": 3027,
                "countryName": "Hungary",
                "categoryId": 2,
                "code": "HU"
            },
            {
                "countryId": 9,
                "countryName": "India",
                "categoryId": 1,
                "code": "IN"
            },
            {
                "countryId": 28,
                "countryName": "Ireland",
                "categoryId": 2,
                "code": "IE"
            },
            {
                "countryId": 19,
                "countryName": "Italy",
                "categoryId": 2,
                "code": "IT"
            },
            {
                "countryId": 10,
                "countryName": "Japan",
                "categoryId": 1,
                "code": "JP"
            },
            {
                "countryId": 3076,
                "countryName": "Luxembourg",
                "categoryId": 3,
                "code": "LU"
            },
            {
                "countryId": 3018,
                "countryName": "Malaysia",
                "categoryId": 3,
                "code": "MY"
            },
            {
                "countryId": 11,
                "countryName": "Mexico",
                "categoryId": 1,
                "code": "MX"
            },
            {
                "countryId": 12,
                "countryName": "Netherlands",
                "categoryId": 1,
                "code": "NL"
            },
            {
                "countryId": 29,
                "countryName": "New Zealand",
                "categoryId": 2,
                "code": "NZ"
            },
            {
                "countryId": 31,
                "countryName": "Norway",
                "categoryId": 2,
                "code": "NO"
            },
            {
                "countryId": 3166,
                "countryName": "Philippines",
                "categoryId": 2,
                "code": "PH"
            },
            {
                "countryId": 30,
                "countryName": "Poland",
                "categoryId": 2,
                "code": "PL"
            },
            {
                "countryId": 3021,
                "countryName": "Romania",
                "categoryId": 3,
                "code": "RO"
            },
            {
                "countryId": 3058,
                "countryName": "Saudi Arabia",
                "categoryId": 3,
                "code": "SA"
            },
            {
                "countryId": 14,
                "countryName": "Singapore",
                "categoryId": 1,
                "code": "SG"
            },
            {
                "countryId": 15,
                "countryName": "South Africa",
                "categoryId": 1,
                "code": "ZA"
            },
            {
                "countryId": 3032,
                "countryName": "South Korea",
                "categoryId": 3,
                "code": "KR"
            },
            {
                "countryId": 18,
                "countryName": "Sweden",
                "categoryId": 2,
                "code": "SE"
            },
            {
                "countryId": 20,
                "countryName": "Switzerland",
                "categoryId": 2,
                "code": "CH"
            },
            {
                "countryId": 3042,
                "countryName": "Thailand",
                "categoryId": 3,
                "code": "TH"
            },
            {
                "countryId": 3059,
                "countryName": "United Arab Emirates",
                "categoryId": 3,
                "code": "AE"
            },
            {
                "countryId": 16,
                "countryName": "United Kingdom",
                "categoryId": 1,
                "code": "GB"
            },
            {
                "countryId": 17,
                "countryName": "United States",
                "categoryId": 1,
                "code": "US"
            }
        ],
        "unSubscribedCountries": [
            {
                "countryId": 3040,
                "countryName": "Austria",
                "categoryId": 2,
                "code": "AT"
            }
        ],
        "tnpSubscribedCountries": null,
        "tnpUnSubscribedCountries": null,
        "ishotjarenabled": false,
        "productDetails": [
            {
                "productName": "TalentNeuron Recruit",
                "pageDetails": [
                    {
                        "pageId": 1,
                        "pageName": "Analyze",
                        "isEnabled": true,
                        "code": "Analyze",
                        "pageProductName": "TalentNeuron Recruit",
                        "permissionDetails": [
                            {
                                "permissionId": 2,
                                "permissionName": "Top Certifications",
                                "permissionCode": "TopCertsTitlesList",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 3,
                                "permissionName": "Top Titles",
                                "permissionCode": "TopTitlesList",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 4,
                                "permissionName": "Top Technical Skills",
                                "permissionCode": "TopSkillTitlesList",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 5,
                                "permissionName": "Top Competencies (Soft Skills)",
                                "permissionCode": "TopSoftSkills",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 6,
                                "permissionName": "Typical Posting Period",
                                "permissionCode": "TypicalPostingPeriod",
                                "permissionLevelId": 2,
                                "permissionLevelName": "Read"
                            },
                            {
                                "permissionId": 7,
                                "permissionName": "Similar Current JDs",
                                "permissionCode": "SimilarJDs",
                                "permissionLevelId": 2,
                                "permissionLevelName": "Read"
                            },
                            {
                                "permissionId": 8,
                                "permissionName": "Education Level",
                                "permissionCode": "TopEducationTitlesList",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 9,
                                "permissionName": "Plain English Hiring Difficulty",
                                "permissionCode": "PlainEnglishHiringDifficulty",
                                "permissionLevelId": 2,
                                "permissionLevelName": "Read"
                            },
                            {
                                "permissionId": 10,
                                "permissionName": "Hiring Difficulty Score",
                                "permissionCode": "HiringDifficultyScore",
                                "permissionLevelId": 2,
                                "permissionLevelName": "Read"
                            },
                            {
                                "permissionId": 11,
                                "permissionName": "Salary Distribution",
                                "permissionCode": "SalaryDistribution",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 12,
                                "permissionName": "Demand Pressure",
                                "permissionCode": "DemandPressure",
                                "permissionLevelId": 2,
                                "permissionLevelName": "Read"
                            },
                            {
                                "permissionId": 13,
                                "permissionName": "Candidate Supply vs Demand",
                                "permissionCode": "SupplyDemand",
                                "permissionLevelId": 2,
                                "permissionLevelName": "Read"
                            },
                            {
                                "permissionId": 14,
                                "permissionName": "Average Candidate Summary",
                                "permissionCode": "AverageCandidateSummary",
                                "permissionLevelId": 2,
                                "permissionLevelName": "Read"
                            },
                            {
                                "permissionId": 15,
                                "permissionName": "Plain English Advice",
                                "permissionCode": "PlainEnglishAdvice",
                                "permissionLevelId": 2,
                                "permissionLevelName": "Read"
                            },
                            {
                                "permissionId": 16,
                                "permissionName": "Competitive Intensity",
                                "permissionCode": "CompetitiveIntensity",
                                "permissionLevelId": 2,
                                "permissionLevelName": "Read"
                            },
                            {
                                "permissionId": 17,
                                "permissionName": "Alternate Locations",
                                "permissionCode": "AlternateLocations",
                                "permissionLevelId": 2,
                                "permissionLevelName": "Read"
                            },
                            {
                                "permissionId": 18,
                                "permissionName": "Salary Wizard",
                                "permissionCode": "SalaryWizard",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 19,
                                "permissionName": "Top Seniority Levels",
                                "permissionCode": "TopSeniorityLevels",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 20,
                                "permissionName": "Top Employers",
                                "permissionCode": "TopEmployers",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 21,
                                "permissionName": "Top Functions",
                                "permissionCode": "TopFunctions",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 33,
                                "permissionName": "Key position stats Analyze - Source",
                                "permissionCode": "KeyPositionStats",
                                "permissionLevelId": 2,
                                "permissionLevelName": "Read"
                            },
                            {
                                "permissionId": 69,
                                "permissionName": "Plain English Advice_v2",
                                "permissionCode": "PlainEnglishAdvice_v2",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 70,
                                "permissionName": "Competitive Intensity_v2",
                                "permissionCode": "CompetitiveIntensity_v2",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 73,
                                "permissionName": "Similar Current JDs_v2",
                                "permissionCode": "SimilarJDs_v2",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            }
                        ]
                    },
                    {
                        "pageId": 2,
                        "pageName": "Source",
                        "isEnabled": true,
                        "code": "Source",
                        "pageProductName": "TalentNeuron Recruit",
                        "permissionDetails": [
                            {
                                "permissionId": 23,
                                "permissionName": "Look For Candidates",
                                "permissionCode": "LookForCandidates",
                                "permissionLevelId": 2,
                                "permissionLevelName": "Read"
                            },
                            {
                                "permissionId": 24,
                                "permissionName": "Installed Talent",
                                "permissionCode": "InstalledTalent",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 25,
                                "permissionName": "Candidates",
                                "permissionCode": "Candidates",
                                "permissionLevelId": 2,
                                "permissionLevelName": "Read"
                            },
                            {
                                "permissionId": 26,
                                "permissionName": "Competing Company",
                                "permissionCode": "CompetingCompany",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 45,
                                "permissionName": "Gender Diversity",
                                "permissionCode": "GenderDiversity",
                                "permissionLevelId": 2,
                                "permissionLevelName": "Read"
                            },
                            {
                                "permissionId": 46,
                                "permissionName": "Female Candidates",
                                "permissionCode": "FemaleCandidate",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 47,
                                "permissionName": "Ethnicity Distribution",
                                "permissionCode": "EthnicityDistribution",
                                "permissionLevelId": 2,
                                "permissionLevelName": "Read"
                            },
                            {
                                "permissionId": 48,
                                "permissionName": "Diverse Candidates",
                                "permissionCode": "DiverseCandidates",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 53,
                                "permissionName": "Alternate Locations_v2",
                                "permissionCode": "AlternateLocations_v2",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 71,
                                "permissionName": "Gender Diversity_v2",
                                "permissionCode": "GenderDiversity_v2",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 72,
                                "permissionName": "Ethnicity Distribution_v2",
                                "permissionCode": "EthnicityDistribution_v2",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            }
                        ]
                    }
                ]
            },
            {
                "productName": "Admin",
                "pageDetails": [
                    {
                        "pageId": 3,
                        "pageName": "Admin",
                        "isEnabled": true,
                        "code": "Admin",
                        "pageProductName": "Admin",
                        "permissionDetails": [
                            {
                                "permissionId": 301,
                                "permissionName": "Admin Module",
                                "permissionCode": "adminmodule",
                                "permissionLevelId": 17,
                                "permissionLevelName": "Read and Edit"
                            }
                        ]
                    }
                ]
            },
            {
                "productName": "TalentNeuron Plan",
                "pageDetails": [
                    {
                        "pageId": 84,
                        "pageName": "Talent",
                        "isEnabled": true,
                        "code": "rolesmodule",
                        "pageProductName": "TalentNeuron Plan",
                        "permissionDetails": [
                            {
                                "permissionId": 366,
                                "permissionName": "Talent Trends",
                                "permissionCode": "roles-occupations",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 367,
                                "permissionName": "Hiring Indicators",
                                "permissionCode": "roles-hiringindicators",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 368,
                                "permissionName": "Talent Diversity",
                                "permissionCode": "roles-diversity",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 369,
                                "permissionName": "Talent Remote work",
                                "permissionCode": "roles-remotework",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            }
                        ]
                    },
                    {
                        "pageId": 86,
                        "pageName": "Features",
                        "isEnabled": true,
                        "code": "planfeatures",
                        "pageProductName": "TalentNeuron Plan",
                        "permissionDetails": [
                            {
                                "permissionId": 377,
                                "permissionName": "Report Builder",
                                "permissionCode": "report-builder",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 378,
                                "permissionName": "Components & Projects Publish",
                                "permissionCode": "projectscomponents-managepublish",
                                "permissionLevelId": 17,
                                "permissionLevelName": "Read and Edit"
                            },
                            {
                                "permissionId": 379,
                                "permissionName": "Industries filter",
                                "permissionCode": "industryfilter",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            }
                        ]
                    },
                    {
                        "pageId": 44,
                        "pageName": "Competition",
                        "isEnabled": true,
                        "code": "competitionmodule",
                        "pageProductName": "TalentNeuron Plan",
                        "permissionDetails": [
                            {
                                "permissionId": 267,
                                "permissionName": "Competitors",
                                "permissionCode": "competition-competitors",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 268,
                                "permissionName": "Location",
                                "permissionCode": "competition-locations",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 269,
                                "permissionName": "Job design",
                                "permissionCode": "competition-jobdesign",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            }
                        ]
                    },
                    {
                        "pageId": 45,
                        "pageName": "Skills",
                        "isEnabled": true,
                        "code": "skillsmodule",
                        "pageProductName": "TalentNeuron Plan",
                        "permissionDetails": [
                            {
                                "permissionId": 270,
                                "permissionName": "Top Skills",
                                "permissionCode": "skills-topskills",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 271,
                                "permissionName": "Skill Adjacencies",
                                "permissionCode": "skills-adjacencies",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 272,
                                "permissionName": "Skills Evolution",
                                "permissionCode": "skills-evolution",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 273,
                                "permissionName": "Skills Placeholder2",
                                "permissionCode": "skills-placeholder2skills",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            }
                        ]
                    },
                    {
                        "pageId": 46,
                        "pageName": "Locations",
                        "isEnabled": true,
                        "code": "locationsmodule",
                        "pageProductName": "TalentNeuron Plan",
                        "permissionDetails": [
                            {
                                "permissionId": 274,
                                "permissionName": "Global Location Scan",
                                "permissionCode": "locations-globallocationscan",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 275,
                                "permissionName": "Location - Summary",
                                "permissionCode": "locations-summary",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 276,
                                "permissionName": "Location - Talent Pool",
                                "permissionCode": "locations-talentpool",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 402,
                                "permissionName": "Location - Graduate Talent",
                                "permissionCode": "locations-graduatetalent",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 403,
                                "permissionName": "Location - Demand",
                                "permissionCode": "locations-demand",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 404,
                                "permissionName": "Location - Competitors",
                                "permissionCode": "locations-competitors",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 405,
                                "permissionName": "Location - Cost",
                                "permissionCode": "locations-cost",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 406,
                                "permissionName": "Location - Factors",
                                "permissionCode": "locations-locationfactors",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            },
                            {
                                "permissionId": 408,
                                "permissionName": "Location Diversity",
                                "permissionCode": "locations-diversity",
                                "permissionLevelId": 3,
                                "permissionLevelName": "Read and Export"
                            }
                        ]
                    }
                ]
            },
            {
                "productName": "Custom Roles",
                "pageDetails": [
                    {
                        "pageId": 24,
                        "pageName": "Custom Roles",
                        "isEnabled": true,
                        "code": "CustomRoles",
                        "pageProductName": "Custom Roles",
                        "permissionDetails": null
                    }
                ]
            },
            {
                "productName": "Datafeeds",
                "pageDetails": [
                    {
                        "pageId": 88,
                        "pageName": "Datafeeds",
                        "isEnabled": true,
                        "code": "Datafeeds",
                        "pageProductName": "Datafeeds",
                        "permissionDetails": null
                    }
                ]
            },
            {
                "productName": "TalentNeuron API",
                "pageDetails": [
                    {
                        "pageId": 90,
                        "pageName": "TalentNeuron API",
                        "isEnabled": true,
                        "code": "TalentNeuron API",
                        "pageProductName": "TalentNeuron API",
                        "permissionDetails": null
                    }
                ]
            }
        ],
        "isMarketingEmailActive": true,
        "isSharedSearchOptedIn": true,
        "isStaffingOptedIn": true,
        "city": "",
        "country": "",
        "title": "",
        "personaID": 15,
        "responsibilityIdsCSV": "211",
        "isProductsUpdateOptedIn": true,
        "isAnonymousOptedIn": false,
        "isOpenJobOptedIn": false,
        "isNewJobOptedIn": true
    }
}