import React, { useState } from 'react'

import { ViewAsModal } from 'tn-components-library'
import { getMimicClientList, beginClientMimic } from '../../http/viewAs'
import KeyCloakAuthInstance from '../../auth/keyAuth'

const clientMimicError = 'Error occured while mimicking client'

const getSuggestionList = (term) => {
  return getMimicClientList(term)
}

const ViewAs = ({ setViewAsModalOpen }) => {
  const [suggestions, setSuggestions] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [errMsg, setErrMsg] = useState('')
  const handleClientViewClick = async (selectedSuggestion) => {
    if (selectedSuggestion) {
      try {
        const clientMimic = await beginClientMimic(selectedSuggestion.id)
        console.log(clientMimic)
        if (!clientMimic.isError) {
          KeyCloakAuthInstance.logout()
          return
        }
        setErrMsg(clientMimicError)
      } catch (e) {
        setErrMsg(clientMimicError)
      }
    }
  }
  const debounce = (func, timeout) => {
    let timer
    return (isClient, searchTerm) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        func(isClient, searchTerm)
      }, timeout)
    }
  }
  const getSuggestions = debounce(async (isClient, searchTerm) => {
    setLoading(true)
    let suggestionData = []
    try {
      suggestionData = await getSuggestionList(searchTerm)
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
    setSuggestions(suggestionData)
  }, 300)

  return (
    <ViewAsModal
      suggetions={suggestions}
      onCancel={() => setViewAsModalOpen(false)}
      getSuggestions={getSuggestions}
      isResultLoading={isLoading}
      errorMessage={errMsg}
      setErrorMessage={setErrMsg}
      handleClientViewClick={handleClientViewClick}
      hideProspectSearch
    />
  )
}

export default ViewAs
