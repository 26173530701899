import axios from 'axios';
import KeyCloakAuthInstance from '../auth/keyAuth';

const http = axios.create({
    withCredentials: true
})

export const XHRCancelMessage = 'Cancelled'
export const XHRAborted = 'Request aborted'

http.all = axios.all
http.spread = axios.spread

http.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${KeyCloakAuthInstance.getAccessToken()}`;
    return config
});

http.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.message && (error.message === XHRCancelMessage || error.message === XHRAborted)) {
        return Promise.reject(error)
      }
    if (error.response && error.response.status === 403) {
        return KeyCloakAuthInstance.logout();
    }
    if ((typeof(error.response) === 'undefined' || error.response.status === 401) ) {
      window.location = window.Gemini.Config.loginURL;
    } else {
        return Promise.reject(error);
    }
});

export default http;