import KeyCloakAuthInstance from "../auth/keyAuth";

const gtmDataLayer = function () {
    return window.dataLayer || [];
}

const defaultData = {
    page: 'Log In Experience',
    category: 'Product Card',
    action: '',
    label: null
}

const init = (userContext) => {
    if (!userContext) {
        return null;
    }
    const sessionId = KeyCloakAuthInstance.getParsedToken().sid;
    if (!sessionId || sessionId === undefined) {
        return null;
    }
    gtmDataLayer().push({
        product: 1,
        user: {
            userId: userContext.userID,
            sessionId
        },
        trackingUrl: `${window.Gemini.Config.adminpublicserviceURL}/v1/ut/userInteraction`
    })
}

const events = {
    //MUST prefix "tnr." to all event names

    linkClick: {
        event: 'tnr.linkClick',
        action: 'Link Clicked'
    },
    viewed: {
        event: "tnr.viewed",
        action: "Viewed"
    },
    click: {
        event: 'tncr.click'
    }
}

const track = (gtmEvent, eventData) => {
    let trackingInfo = { ...defaultData, ...gtmEvent, ...eventData };

    if (trackingInfo.page && trackingInfo.category && trackingInfo.action) {
        gtmDataLayer().push(trackingInfo);
    }
}


export default { init, events, track }