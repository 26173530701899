import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader, Button } from 'tn-components-library';
import http from "../../http";
import { addEllipsis } from "../../utils";
import "./styles.css"
import gtm from '../../usagetracker/GTM';


const imageTypes = {
	png : "data:image/png;base64,",
	svg : "data:image/svg+xml;base64,",
	jpg : "data:image/jpg;base64,",
	jpeg : "data:image/jpg;base64,",
}
export default class Insight extends Component {

    constructor(props) {
        super(props);
        this.getInsightDetails = this.getInsightDetails.bind(this);
        this.state = {
            insights: [],
            loading: true,
            imageData: {}
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.props.insights !== newProps.insights) {
            const insights = newProps.insights || []
            const insightslist = insights.slice(0, 3);
            this.getImageData(insightslist)
            this.setState({
                insights: insightslist
            });
        }
    }

    getImageData(insightslist = []){
        const promises = []
        insightslist.forEach(x => {
            promises.push(http.get(`${window.Gemini.Config.adminpublicserviceURL}/v1/cms/tninsights/image/${x.id}`))
        })
        Promise.all([...promises]).then((responses) => {
            const _images = {}
            responses.forEach((response) => {
                if (response && response.data && !response.data.isError && response.data.data && response.data.data.data) {
                    _images[response.data.data.id] =(`${imageTypes[response.data.data.extn.toLowerCase()]}${response.data.data.data}`)
                }
            })
            this.setState({
                imageData: _images,
                loading: false
            })
        })
	}

    getInsightDetails(insight) {
        const anchor = document.createElement('a');
        anchor.href = `${window.Gemini.Config.homeURL}/learnmore/insights/${insight.id}`;
        anchor.target = "_blank";
        anchor.click();
        gtm.track(gtm.events.linkClick, { page: 'TN Homepage', category: 'Insights', action: 'Insights opened' });
    }

    render() {
        const {openURL, product} = this.props
        const {imageData} = this.state;
        return (
            <div className='insight-container'>
                <div className='insight-header'>
                    <div className='insight-title'>Insights</div>
                </div>
                {this.state.loading && <Loader  type='dark' />}
                <div className='insight-section-container'>                    
                    {this.state.insights.length && !this.state.loading  ? this.state.insights.map(insight => {
                        const date = new Date(insight.date).toLocaleString('en-us',{month:'short', year:'numeric'})
                        return (
                            <div className='insight-section-block' key={insight.id} onClick={() => this.getInsightDetails(insight)}>
                                <img src={imageData[insight.id]} alt='insight-image' width={'100%'} height={191} />
                                <div className='header'>{addEllipsis(insight.title, 55)}</div>
                                <span className='date'>{date}</span>
                            </div>
                        );
                    }) : null}
                </div>
                <div className='insight-section-button'>
                    <Button
                        category="primary-outline"
                        onClick={() => openURL(product.urlkey)}
                        outline="true"
                        label={product.btntext}
                    />
                </div>
            </div>
        );
    }
}

Insight.contextTypes = {
    intl: PropTypes.object.isRequired
}