import React from 'react';
import {getFormattedDate} from './../../utils';
import { Icon, Tooltip } from 'tn-components-library';
import "./styles.css";

const CreditInfoToolTip = ({ title }) => {
    return (
        <div className='credit-tooltip-wrapper'>
            <Tooltip
                type='info' 
                className='subscription-credits-info-tooltip'
                title={title}
                arrow
                placement='top'
            >
                <Icon name="Info" />
            </Tooltip>
        </div>
    )
}

const SubscriptionDetails = (props) => {
    const intl = props.intl

    const emailSecretsClickHandler = (eve, subscriptionKey, subscriptionName) => {
        (!eve.detail || eve.detail == 1) && props.emailCallback(subscriptionKey,subscriptionName, eve.target)
    }

    if(props.data){
        return (
            <div className="subscriptionDetails">
                <div className="fieldContainer">
                    <div className='sub-label'>
                    {intl.formatMessage({ id: 'api_suites' })}
                    </div>
                    <div className='sub-value'>
                        {props.apiSuite}
                    </div>
                </div>
                <div className="fieldContainer">
                    <div className='sub-label'>
                    {intl.formatMessage({ id: 'type' })}
                    </div>
                    <div className='sub-value'>
                        {props.type}
                    </div>
                </div>
                <div className="fieldContainer">
                    <div className='sub-label'>
                    {intl.formatMessage({ id: 'status' })}
                    </div>
                    <div className='sub-value'>
                        {props.status}
                    </div>
                </div>
                <div className="fieldContainer">
                    <div className='sub-label'>
                    {intl.formatMessage({ id: 'validity' })}
                    </div>
                    <div className='sub-value'>
                        {getFormattedDate(props.data.startDate)} - {getFormattedDate(props.data.endDate)}
                    </div>
                </div>
                <div className="fieldContainer">
                    <div className='sub-label'>
                        <span>{intl.formatMessage({ id: 'allocated_credits' })}</span>
                        <span>({intl.formatMessage({ id: 'applicable_users' })})</span>
                    </div>
                    <div className='sub-value'>
                        {props.data.allocatedCredits}
                    </div>
                </div>
                <div className="fieldContainer">
                    <div className='sub-label'>
                    {intl.formatMessage({ id: 'remaining_credits' })}
                    </div>
                    <div className='sub-value'>
                        {props.data.allocatedCredits - props.data.consumedCreditsOfClient}
                        <CreditInfoToolTip  title={intl.formatMessage({ id: 'total_credits_remaining_for_organization' })} />
                    </div>
                </div>
                <div className="fieldContainer">
                    <div className='sub-label'>
                    {intl.formatMessage({ id: 'credits_used' })}
                    </div>
                    <div className='sub-value'>
                        {props.data.consumedCreditsOfUser}
                        <CreditInfoToolTip  title={intl.formatMessage({ id: 'total_credits_remaining_for_you' })} />
                    </div>
                </div>
                <div className="fieldContainer">
                    <div className='sub-label'>
                    {intl.formatMessage({ id: 'locations' })}
                    </div>
                    <div className='sub-value'>
                        {props.data.countries.join(", ")}
                    </div>
                </div>
                <div className="fieldContainer">
                    <div className='sub-label'>
                    {intl.formatMessage({ id: 'api_usecases' })}
                    </div>
                    <div className='sub-value'>
                        {props.data.useCases.join(",")}
                    </div>
                </div>
                <div className="fieldContainer">
                    <div className='sub-label'>
                    {intl.formatMessage({ id: 'historical_data' })}
                    </div>
                    <div className='sub-value'>
                        {props.data.apiQueryRangeValue}
                    </div>
                </div>
                { props.isActive && (<div className="fieldContainer">
                    <div className='sub-label'>
                        <span className="emailKeyCTA" onClick={(e)=>{emailSecretsClickHandler(e, props.data.subscriptionKey,props.data.subscriptionName)}}>{intl.formatMessage({ id: 'email_secret' })}</span>
                    </div>
                    <div className='sub-value'>
                        
                    </div>
                </div>)}
            </div>
        )
    }else{
        return <div></div>
    }
}


export default SubscriptionDetails;