import http from "./index";

const getUserProfile  = () => {
    return http.get(`${window.Gemini.Config.adminpublicserviceURL}/v1/user/getuserbyemail?email=${window.Gemini.UserData.userEmail}`)
            .then(res => {
                if(res == null || res.data.isError){
                    console.log("Error =>" + res.data )
                }
                return res.data
            })
            .catch(err =>{
                 console.log(err);
                }
            )
}

export default {getUserProfile}

